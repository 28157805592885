export function useSecurityPosture() {
  const { org } = useCurrentOrg()
  const { currentFrameworkProgress } = useFrameworkLevels()

  const postureScore = computed(
    () =>
      Math.floor(
        (currentFrameworkProgress.value + (org.value?.score || 0) / 10) / 2,
      ) * 10,
  )

  return { postureScore }
}
